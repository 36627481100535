import React from "react"
import { Link } from "gatsby"
import SEO from "../components/seo"
import FullScreenPanel from "../components/fullscreenpanel"
import TextPanel from "../components/textpanel"

const IndexPage = () => (
  <>
    <SEO title="Home" keywords={[`website`, `design`, `development`]} />
    <FullScreenPanel>
      <TextPanel>
        <h1>
          This is TOBOGGAN STUDIOS
          <span>.</span>
        </h1>
        <p>We&apos;re a small, interdisciplinary digital studio with competencies in design, engineering, and strategy.</p>
      </TextPanel>
    </FullScreenPanel>
    <FullScreenPanel>
      <TextPanel>
        <h1>
          Let&apos;s slide
          <span>.</span>
        </h1>
        <p>We specialize in front-end website development and interface creation for digitally-savvy clients and diverse audiences. To learn more about how we work and what we can do, say hi.</p>
        <p>
          View our
          {" "}
          <Link to="/privacy/">Privacy Policy</Link>
        </p>
      </TextPanel>
    </FullScreenPanel>
  </>
)

export default IndexPage