/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import * as textPanelCSS from "./textpanel.module.sass"

const TextPanel = ({ children }) => (
  <div className={textPanelCSS.panel}>
    {children}
  </div>
)
export default TextPanel
